import {  Container, Divider, Grid, Header, Image, Segment} from "semantic-ui-react";

import React from "react";
import PropTypes from "prop-types";

const HomeLayout = () => (
    <div>
        <Container text>
            {/*<Image bordered src={"Bewerbungsbild_6x4.5.jpg"}*/}
            {/*       style={{*/}
            {/*           fontSize: true ? '2em' : '4em',*/}
            {/*           fontWeight: 'normal',*/}
            {/*           marginBottom: 0,*/}
            {/*           marginTop: true ? '1.5em' : '3em',*/}
            {/*       }}*/}
            {/*/>*/}

        </Container>
        <Segment style={{ padding: '8em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Cisco
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Cisco Certified Network Associate - 2021<br/>
                            Netacad Instructor Akkreditierung für DEVNET - 2021
                        </p>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Cisco Networking Academy
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            {/*TODO, accordion mit den versch sachen*/}
                            Cybersecurity Operations - 2019<br/>
                            CCNA - 2020 <br/>
                            CCNA Security - 2021 <br/>
                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        <Image size='large' src="ccna_large.jpg"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            React
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Die Sprache meiner Wahl für Web Apps. <br/>
                        </p>
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            C++
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Mehrjährige Erfahrung im Umgang mit C und C++, insbesondere auch moderne Trends wie in C++17 und C++20.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            CMake
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            CMake ist ein wunderbares Werkzeug zum Builden und Vorbereiten von C++ Code.
                            Integrationen, wie mit Doxygen oder Code Generation, helfen vorhandenen Code anzupassen oder on the fly zu dokumentieren.
                        </p>
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            SQL
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Ein solides Verständnis von relationalen Datenbanken und ihrem Aufbau hilft in vielen Situationen.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row textAlign='center'>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Fortran
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Das Verständnis von alten Sprachen erweißt sich nicht nur in der klassischen Archäologie als hilfreich.
                        </p>
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            PHP
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            PHP ist nach wie vor gerade im Webbereich stark vertreten.
                            Einblick und das Verständnis bei der Fehlersuche erweisen sich oft als hilfreich.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Segment style={{ padding: '8em 0em' }} vertical>
            <Container text>
                <Header as='h3' style={{ fontSize: '2em' }}>
                    Dev Ops
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                    Nach sieben Jahren Gitlab ist mir die CI richtig ans Herz gewachsen. Das Abbilden von Prozessen ist angenehmer denn je,
                    aber auch das Sicherstellen von Codequalität und das Deployment haben viele Schritte angenehmer gemacht.

                </p>

                <Divider
                    as='h4'
                    className='header'
                    horizontal
                    style={{ margin: '3em 0em', textTransform: 'uppercase' }}
                >
                    {/*TODO , auch was über linux schreiben*/}
                    {/*<a href='#'>Lebenslauf</a>*/}
                </Divider>

                <Header as='h3' style={{ fontSize: '2em' }}>
                    Habe Ich Ihnen schon von meinen Hobbys erzählt?
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                    Fotografie ist meine Leidenschaft.
                    Insbesondere die Astrofotografie ist hier in meinen Fokus geraten.<br/>
                    Ansonsten spiele gerne mal Klavier oder Akkordeon.
                </p>
                {/*<Button as='a' size='large'>*/}
                {/*    Astrofotografie?*/}
                {/*</Button>*/}
            </Container>
        </Segment>
    </div>
)
HomeLayout.propTypes = {
    mobile: PropTypes.bool,
}
export default HomeLayout;
