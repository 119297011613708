import React, {Component} from "react";
import { Button, Container, Icon, Menu, Segment, Sidebar} from "semantic-ui-react";
import {HomepageHeading} from "./HomepageHeading";
import {Media} from "./Media";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
}
export default class MobileContainer extends Component {
    state = {}

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
        const { children } = this.props
        const { sidebarOpened } = this.state

        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                    >
                        {/*<Menu.Item as={Link} to='/' active>Home</Menu.Item>*/}
                        <Menu.Item as={Link} to='/' >Home</Menu.Item>
                        <Menu.Item as={Link} to='/lebenslauf'>Lebenslauf</Menu.Item>
                        <Menu.Item as={Link} to='/certifications'>Certifications</Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            inverted
                            textAlign='center'
                            style={{ minHeight: 350, padding: '1em 0em' }}
                            vertical
                        >
                            <Container>
                                <Menu inverted pointing secondary size='large'>
                                    <Menu.Item onClick={this.handleToggle}>
                                        <Icon name='sidebar' />
                                    </Menu.Item>
                                    <Menu.Item position='right'>
                                        <Button as={Link} to='impressum' inverted>
                                            Impressum
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            </Container>
                            <HomepageHeading mobile/>
                        </Segment>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}
