import {Container, Grid, Header, Image} from "semantic-ui-react";
import React from "react";

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
export const HomepageHeading = ({ mobile }) => (
    <Container text>
        <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column width={5}>
                    <Image bordered
                           src={"Bewerbungsbild_6x4.5.jpg"}
                           style={{
                               width: mobile ? '15em': '30em',
                               marginBottom: 0,
                           }}
                    />
                </Grid.Column>
                <Grid.Column floated='right' width={11}>
                    <Header
                    as='h1'
                    content='Norbert L. Rühl'
                    inverted
                    style={{
                        fontSize: mobile ? '2em' : '4em',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        marginTop: mobile ? '1.5em' : '3em',
                    }}
                />
                    {/*<Header*/}
                    {/*    as='h2'*/}
                    {/*    // content={'BSC Informatik'}*/}
                    {/*    // content='Unter Druck entstehen Diamanten.'*/}
                    {/*    inverted*/}
                    {/*    style={{*/}
                    {/*        fontSize: mobile ? '1.5em' : '1.7em',*/}
                    {/*        fontWeight: 'normal',*/}
                    {/*        marginTop: mobile ? '0.5em' : '1.5em',*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Grid.Column>
            </Grid.Row>
        </Grid>



    </Container>
)