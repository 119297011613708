import { Grid, Header, Segment} from "semantic-ui-react";
import Obfuscate from 'react-obfuscate'
import React from "react";



const ImpressumLayout = () => (
    <div>
        <Segment style={{ padding: '8em 0em' }} vertical>
            <Grid container stackable>
                    <Grid.Column width={10}>
                        <Header as='h1' style={{ fontSize: '2em' }}>
                            Impressum
                        </Header>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Angaben gemäß §5 TMG
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Norbert L. Rühl <br/>
                            Im Grund 3 <br/>
                            91484 Markt Sugenheim <br/>
                        </p>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Kontakt
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Telefon: <Obfuscate style={{ display: 'inline-block' }} tel="+49 9165 24907907" />
                            <br/>
                            Email: <Obfuscate style={{ display: 'inline-block' }} email="63762d696d7072657373756d32303231@norbert-ruehl.de" />
                        </p>
                    </Grid.Column>
            </Grid>
        </Segment>


    </div>
)
export default ImpressumLayout;