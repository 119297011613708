import {Divider, Grid, Header, Image, List, Segment} from "semantic-ui-react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck} from "@fortawesome/pro-regular-svg-icons";


const CertificationsLayout = () => (
    <div>

        <Segment style={{ padding: '8em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Cisco
                        </Header>
                        <p style={{ fontSize: '1.33em' }}>
                            Cisco Certified Network Associate - 2021<br/>
                            <List.Item as='a' href={'https://www.credly.com/badges/da707762-ba7f-4ee3-8599-46b5bcb3bc0c'}><FontAwesomeIcon fixedWidth icon={faBadgeCheck}/>Credly</List.Item>

                        </p>
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        <Image size='large' src="ccna_large.jpg"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Cisco Networking Academy
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2021
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Netacad Instructor Akkreditierung für DEVNET - 2021<br/>
                                        <List.Item as='a' href={'https://www.credly.com/badges/5f7c9e07-bb02-4f96-8f15-daccd17d4035'}><FontAwesomeIcon fixedWidth icon={faBadgeCheck}/>Credly</List.Item>
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>



                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2021
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        CCNA Security
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2019
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        CCNA Cybersecurity Operations
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2018-2020
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        CCNA
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Netbeans
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2013
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Netbeans Certified Associate
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*    <Grid.Column width={16}>*/}
                {/*        <Header as='h3' style={{ fontSize: '2em' }}>*/}
                {/*            Linux*/}
                {/*        </Header>*/}
                {/*        <Segment>*/}
                {/*            <Grid columns={2} relaxed='very'>*/}
                {/*                <Grid.Column>*/}
                {/*                    <p style={{ fontSize: '1.33em' }}>*/}
                {/*                        2016*/}
                {/*                    </p>*/}
                {/*                </Grid.Column>*/}
                {/*                <Grid.Column>*/}
                {/*                    <p style={{ fontSize: '1.33em' }}>*/}
                {/*                        LFS101x.2*/}
                {/*                    </p>*/}
                {/*                </Grid.Column>*/}
                {/*            </Grid>*/}
                {/*            <Divider vertical/>*/}
                {/*        </Segment>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid.Row>*/}
            </Grid>
        </Segment>


    </div>
)
export default CertificationsLayout;