import React, {Component} from "react";
import {Container, Menu, Segment, Visibility} from "semantic-ui-react";
import {HomepageHeading} from "./HomepageHeading";
import {Media} from "./Media";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
}

export default class DesktopContainer extends Component{
    /* Heads up!
     * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
    * It can be more complicated, but you can create really flexible markup.
    */

    state = {}

    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })

    render() {
        const { children } = this.props
        const { fixed } = this.state

        return (
            <Media greaterThan='mobile'>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: 400, padding: '1em 0em' }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? 'top' : null}
                            inverted={!fixed}
                            pointing={!fixed}
                            secondary={!fixed}
                            size='large'
                        >
                            <Container>
                                {/*<Menu.Item as={Link} to='/' active>Home</Menu.Item>*/}
                                <Menu.Item as={Link} to='/' >Home</Menu.Item>
                                <Menu.Item as={Link} to='/lebenslauf'>Lebenslauf</Menu.Item>
                                <Menu.Item as={Link} to='/certifications'>Certifications</Menu.Item>

                                <Menu.Item position='right'>
                                    <Menu.Item as={Link} to='/impressum'>Impressum</Menu.Item>
                                </Menu.Item>
                            </Container>
                        </Menu>
                        <HomepageHeading/>
                    </Segment>
                </Visibility>
                {children}
            </Media>
        )
    }
}
