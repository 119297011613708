/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import {MediaContextProvider} from "./Homepage/Media";
import PropTypes from 'prop-types'
import React from 'react'
import {Route,Switch} from "react-router-dom"


import DesktopContainer from "./Homepage/DesktopContainer";
import MobileContainer from "./Homepage/MobileContainer";
import FooterContainer from "./Homepage/FooterContainer";
import HomeLayout from "./Layout/Home";
import LebenslaufLayout from "./Layout/Lebenslauf";

import ImpressumLayout from "./Layout/Impressum";
import CertificationsLayout from "./Layout/Certifications";



DesktopContainer.propTypes = {
    children: PropTypes.node,
}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    <MediaContextProvider>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}


const HomepageLayout = () => (
        <ResponsiveContainer>
            <Switch>
                <Route path="/lebenslauf" component={LebenslaufLayout} />
                {/*<Route path="/" component={ProjekteLayout} />*/}
                <Route path="/impressum" component={ImpressumLayout} />
                <Route path="/certifications" component={CertificationsLayout} />
                <Route path="/" component={HomeLayout} />
            </Switch>;
         <FooterContainer/>
     </ResponsiveContainer>
 )

export default HomepageLayout