import {Divider, Grid, Header,  Segment} from "semantic-ui-react";

import React from "react";



const LebenslaufLayout = () => (
    <div>
        <Segment style={{ padding: '8em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Bildung
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2003-2012
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Abitur <br/> Gymnasium Scheinfeld
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2012-2017
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Studium - Bachelorstudiengang <br/>Luft und Raumfahrt Informatik (Würzburg) <br/> Ohne Abschluss.
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2017-Aktuell
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Studium - Bachelorstudiengang <br/>Informatik (Würzburg)
                                    </p>
                                </Grid.Column>
                            </Grid>

                            <Divider vertical/>
                        </Segment>

                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Praktika / Nebentätigkeiten
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        12/2010 - Aktuell
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Website, Blog, IT-Infrastruktur
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        05/2013 - 07/2016
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Hilfswissenschaftler Physik Uni Würzburg/IUK <br/>
                                        PHP Entwicklung, EAS
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        05/2013 - 07/2016
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Hilfswissenschaftler Physik Uni Würzburg/IUK <br/>
                                        PHP Entwicklung, PVD
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2015
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Dozententätigkeit <br/>
                                        Rechenzentrum Uni Würzburg <br/>
                                        Raspberry Pi Kurs
                                    </p>
                                </Grid.Column>
                            </Grid>

                            <Divider vertical/>
                        </Segment>


                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Sprachen
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Deutsch
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Muttersprache
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Englisch
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        fließend in Wort und Schrift <br/>
                                        B1
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Französisch
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        fließend in Wort und Schrift <br/>
                                        B1
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>

                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Freizeitaktivitäten
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Tischtennis
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Kochen
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Astronomie
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Fotografie
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>

                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Ehrenamtliche Tätigkeiten / Schulische Aktivitäten
                        </Header>
                        <Segment>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2011
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Klassensprecher
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2007
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Klassensprecher
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        2009
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Schülerzeitung
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Grid columns={2} relaxed='very'>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>

                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <p style={{ fontSize: '1.33em' }}>
                                        Schulsanitäter
                                    </p>
                                </Grid.Column>
                            </Grid>
                            <Divider vertical/>
                        </Segment>

                    </Grid.Column>
                    {/*<Grid.Column floated='right' width={6}>*/}
                    {/*    <Image bordered rounded size='large' src="ccna_large.jpg"/>*/}
                    {/*</Grid.Column>*/}
                </Grid.Row>
            </Grid>
        </Segment>


    </div>
)
export default LebenslaufLayout;
