import {Container, Grid, Header, List, Segment} from "semantic-ui-react";
import React, {Component} from "react";
import {faGithub, faGitlab, faLinkedin, faXing} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class FooterContainer extends Component {
    render() {
        return (
            <Segment inverted vertical style={{ padding: '5em 0em' }}>
                <Container>
                    <Grid divided inverted stackable>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content='Development' />
                                <List link inverted>
                                    <List.Item as='a' href={'https://github.com/norbekaiser'}><FontAwesomeIcon fixedWidth icon={faGithub}/>Github [github.com]</List.Item>
                                    <List.Item as='a' href={'https://gitlab.norbert-ruehl.de/nruehl'}><FontAwesomeIcon fixedWidth icon={faGitlab}/>Gitlab [norbert-ruehl.de]</List.Item>
                                    <List.Item as='a' href={'https://gitlab.com/nerdbert'}><FontAwesomeIcon fixedWidth icon={faGitlab}/>Gitlab [gitlab.com]</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='Social' />
                                <List link inverted>
                                    <List.Item as='a' href={'https://www.xing.com/profile/Norbert_Ruehl'}><FontAwesomeIcon fixedWidth icon={faXing}/> XING</List.Item>
                                    <List.Item as='a' href={'https://de.linkedin.com/in/norbert-r%C3%BChl-a12760137'}><FontAwesomeIcon fixedWidth icon={faLinkedin}/>LinkedIn</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Header as='h4' inverted>
                                    Misc
                                </Header>
                                <p>
                                    <List link inverted>
                                        <List.Item as={Link} to='/impressum'>Impressum</List.Item>
                                    </List>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        )
    }
}

export default FooterContainer
